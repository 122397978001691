import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from '~/components/Layout';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { ArticleShareBar } from '../components/article/ArticleShareBar';
// Elements
import { AnimatePresence, motion } from 'framer-motion';
import { TooltipCurrent } from '../components/elements/Icon';
import Image from '../components/elements/Image';
import { Link } from 'gatsby';
import { ChevronFont } from '../components/elements/Icon';
import { BlueGlow } from '../components/Glow';

export default function SingleState({ data: { wpStateAdviserRule, wpPage, wp } }) {
  // console.log(wpStateAdviserRule);
  return (
    <Layout wp={wp} hideFooter={false}>
      <Seo post={wpStateAdviserRule} title={wpStateAdviserRule?.title} />
      <StateMasthead wpStateAdviserRule={wpStateAdviserRule} />
      <StateStats wpStateAdviserRule={wpStateAdviserRule} />
      <Summary wpStateAdviserRule={wpStateAdviserRule} />
      <StateRules wpStateAdviserRule={wpStateAdviserRule} />
      <StateArticle wpStateAdviserRule={wpStateAdviserRule} />
      <References wpStateAdviserRule={wpStateAdviserRule} />
      <section>
        <div className={`container mb-8 md:mb-12`}>
          <div className="max-w-[50rem] mx-auto">
            <Link
              to="/state-adviser-rules/"
              className="inline-flex space-x-2 items-center t-15 mb-6 md:mb-8 lg:mb-12 hover:text-teal transition-color duration-200"
            >
              <ChevronFont />
              <span>Return to state explorer</span>
            </Link>
          </div>
          <div className="max-w-[50rem] mx-auto pt-8 md:pt-[40px] border-y border-borderGrey">
            <ArticleShareBar />
          </div>
        </div>
      </section>
    </Layout>
  );
}

function StateMasthead({ wpStateAdviserRule }) {
  const [showGrade, setShowGrade] = useState(false);
  return (
    <section className="bg-navy text-white py-32 lg:pt-[9.0625rem] pb-10 md:pb-[3.375rem] relative">
      <BlueGlow
        classes={`absolute md:left-auto top-auto md:top-0 inset-[0] mr-auto ml-auto opacity-[.5] w-2/3 w-2/3 h-1/2 md:h-full`}
        stopTwo={'20%'}
        stopThree={'60%'}
      />
      <div className="container grid gap-y-6 md:gap-x-8 md:gap-y-0 md:grid-cols-12 md:items-center relative z-10">
        {/* Content */}
        <div className="md:col-span-6 lg:col-span-7">
          <div>
            <Link
              to="/state-adviser-rules/"
              className="inline-flex space-x-2 items-center t-15 mb-6 md:mb-8 lg:mb-12 hover:text-teal transition-color duration-200"
            >
              <ChevronFont />
              <span>Back to previous page</span>
            </Link>
          </div>
          <h1
            className="t-64 mb-8 md:mb-12 lg:mb-16 text-center md:text-left"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.title }}
          />
          <div className="flex justify-center md:justify-start md:inline-flex items-center space-x-3 relative">
            <div className="t-14 rounded-[.5rem] bg-white/10 flex items-stretch justify-between max-w-[10.5625rem]">
              <span className="py-[.375rem] px-3" dangerouslySetInnerHTML={{ __html: 'Repool Grade' }} />
              <div className="bg-blue block rounded-[.5rem] h-auto py-2 px-5">
                <span dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.grades?.nodes[0]?.name }} />
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowGrade(!showGrade)}
              aria-label="Grade modal icon"
              className="aspect-square w-[1.125rem] h-[1.125rem] flex justify-center items-center text-white hover:text-teal"
            >
              <TooltipCurrent />
            </button>
            {/* Modal */}
            <AnimatePresence>
              {showGrade && (
                <motion.div
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -30, opacity: 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className="p-[1.125rem] rounded-[.35rem] bg-white absolute top-[160%] inset-x-0 !mx-auto md:!ml-5 md:top-[-3.5rem] md:left-[105%] min-w-[17.5rem] md:min-w-[27.25rem] max-w-[27.25rem] z-10"
                >
                  <h3
                    className="text-blue t-14 font-body font-medium mb-2"
                    dangerouslySetInnerHTML={{ __html: `Grade ${wpStateAdviserRule?.grades?.nodes[0]?.name}` }}
                  />
                  <p
                    className="t-14 text-navy"
                    dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.grades?.nodes[0]?.description }}
                  />
                  <div className="aspect-[1/1] w-5 h-5 bg-white absolute top-[-.5rem] inset-x-0 mx-auto md:top-[4rem] md:left-[-.5rem] md:right-auto rotate-[45deg]" />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <p
            className="text-14 opacity-[.65] mt-4  text-center md:text-left"
            dangerouslySetInnerHTML={{
              __html: `Last updated: ${wpStateAdviserRule?.postTypeSingleState?.stateDetails?.lastUpdated}`,
            }}
          />
        </div>
        {/* Image */}
        <div className="md:col-span-6 lg:col-span-5">
          {wpStateAdviserRule?.postTypeSingleState?.stateDetails?.mapImage && (
            <Image
              image={wpStateAdviserRule?.postTypeSingleState?.stateDetails?.mapImage}
              className="w-full h-full object-contain md:max-w-[26.5rem] max-w-[14.5rem] max-h-[26.5rem] mx-auto"
            />
          )}
        </div>
      </div>
    </section>
  );
}

function StateStats({ wpStateAdviserRule }) {
  return (
    <section className="relative bg-navy text-white py-8">
      <div className="relative z-10 container grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-4 md:gap-y-0">
        <GridItem
          label="Exemption framework?"
          item={wpStateAdviserRule?.postTypeSingleState?.stateDetails?.exemptionFramework}
        />
        <GridItem
          label="Minimum investor type"
          item={wpStateAdviserRule?.postTypeSingleState?.stateDetails?.minimumInvestorType}
        />
        <GridItem
          label="Audit required?"
          item={wpStateAdviserRule?.postTypeSingleState?.stateDetails?.auditRequired ? 'Yes' : 'No'}
        />
        <GridItem
          label="Nonstandard requirements"
          item={wpStateAdviserRule?.postTypeSingleState?.stateDetails?.nonStandardRequirements ? 'Yes' : 'No'}
        />
      </div>
      <div className="absolute inset-0 bg-blue/20" />
    </section>
  );
}

function GridItem({ label, item }) {
  return (
    <div className="flex flex-col">
      <span
        className="t-10 block font-medium opacity-50 uppercase tracking-[1px] !leading-[1.2] mb-3"
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <h2 className="t-16 mt-auto font-body" dangerouslySetInnerHTML={{ __html: item }} />
    </div>
  );
}

function Summary({ wpStateAdviserRule }) {
  return (
    <section className="pt-12 md:pt-16 lg:pt-[6.5rem]">
      <div className="container">
        {/* Heading */}
        <div className="text-center">
          <span
            className="t-14 inline-flex text-blue uppercase font-medium font-body tracking-[1.4px] mb-4 md:mb-5"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.title }}
          />
          <h2 className="t-42 font-medium mb-8 md:mb-10" dangerouslySetInnerHTML={{ __html: 'Summary' }} />
        </div>
        {/* Content */}
        <div className="max-w-[49.5rem] mx-auto bg-white rounded-[.5rem] p-6 md:p-8 lg:p-12">
          <h3
            className="t-14 text-blue uppercase font-medium font-body tracking-[1.4px] mb-4 md:mb-5"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.postTypeSingleState?.summary?.subheading }}
          />
          <p
            className="t-20 font-navy"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.postTypeSingleState?.summary?.summary }}
          />
        </div>
        {/* Image */}
        <div className="rounded-[.5rem] overflow-hidden aspect-[60/29] max-w-[900px] mx-auto w-full my-12 md:my-16 lg:my-[7.5rem]">
          {wpStateAdviserRule?.postTypeSingleState?.summary?.locationImage && (
            <Image
              image={wpStateAdviserRule?.postTypeSingleState?.summary?.locationImage}
              className="w-full h-full object-cover"
            />
          )}
        </div>
      </div>
    </section>
  );
}

function StateRules({ wpStateAdviserRule }) {
  return (
    <section className="my-12 md:my-16 lg:my-[7.5rem]">
      <div className="container">
        <div className="max-w-[49.5rem] mx-auto">
          <h2
            className="t-32 font-medium mb-4 md:mb-5"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.postTypeSingleState?.stateRules?.heading }}
          />
          <p
            className="prose post-prose !max-w-[792px]"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.postTypeSingleState?.stateRules?.content }}
          />
          <div className="mt-8 md:mt-10 grid gap-y-8 md:gap-y-10">
            {wpStateAdviserRule?.postTypeSingleState?.stateRules?.rules?.map((rule, l) => (
              <div className="flex items-start sm:items-center space-x-4 md:space-x-5 md:pr-3" key={l}>
                {rule?.icon && (
                  <div className="shrink-0 aspect-square bg-whiteBlue/10 rounded-[.5rem] w-[3rem] sm:w-[6rem] flex items-center justify-center p-1 md:p-6">
                    <Image image={rule?.icon} className={'w-full h-full object-contain'} />
                  </div>
                )}
                <div>
                  <h3 className="t-20 font-body font-medium mb-2" dangerouslySetInnerHTML={{ __html: rule?.heading }} />
                  <p className="t-18 opacity-[.8]" dangerouslySetInnerHTML={{ __html: rule?.description }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function StateArticle({ wpStateAdviserRule }) {
  return (
    <section className="my-12 md:my-16 lg:my-[7.5rem]">
      <div className="container">
        <div className="max-w-[49.5rem] mx-auto">
          <h2
            className="t-32 font-medium mb-4 md:mb-5"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.postTypeSingleState?.articleContent?.heading }}
          />
          <div
            className="prose post-prose !max-w-[792px]"
            dangerouslySetInnerHTML={{ __html: wpStateAdviserRule?.postTypeSingleState?.articleContent?.content }}
          />
        </div>
      </div>
    </section>
  );
}

function References({ wpStateAdviserRule }) {
  return (
    <section className="relative my-12 md:my-16 lg:my-[7.5rem]">
      <div id="ref" className="absolute top-[-8rem]" />
      <div className="container">
        <div className="max-w-[49.5rem] mx-auto bg-white rounded-[.5rem] p-6 md:p-8 lg:p-10">
          <h2 className="t-32 font-medium mb-6 md:mb-10" dangerouslySetInnerHTML={{ __html: 'References' }} />
          <div className="w-full grid gap-y-3 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 md:gap-x-8 lg:gap-x-10">
            {wpStateAdviserRule?.postTypeSingleState?.references?.map((item, i) => (
              <a
                href={item?.link?.url}
                target={item?.link?.target}
                className="t-16 font-medium flex items-center space-x-3 md:space-x-[1.175rem] rounded-[.375rem] p-[.375rem] hover:bg-blue hover:text-white group"
                key={i}
              >
                <div className="aspect-square w-8 h-8 bg-[#F7F9FF] text-center flex items-center justify-center rounded-[.25rem] p-1 group-hover:bg-[#456DFD]">
                  <span dangerouslySetInnerHTML={{ __html: i + 1 }} />
                </div>
                <span dangerouslySetInnerHTML={{ __html: item?.link?.title }} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export const pageQuery = graphql`
  query StateAdviserRule($id: String!) {
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    wpStateAdviserRule(id: { eq: $id }) {
      title
      uri
      date
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          altText
          publicUrl
          sourceUrl
          localFile {
            publicURL
          }
        }
      }
      grades {
        nodes {
          name
          description
        }
      }
      postTypeSingleState {
        stateDetails {
          lastUpdated
          exemptionFramework
          minimumInvestorType
          auditRequired
          nonStandardRequirements
          mapImage {
            altText
            srcSet
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
        summary {
          subheading
          summary
          locationImage {
            altText
            srcSet
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
        stateRules {
          heading
          content
          rules {
            icon {
              altText
              srcSet
              sourceUrl
              localFile {
                publicURL
              }
            }
            heading
            description
          }
        }
        articleContent {
          heading
          content
        }
        references {
          link {
            title
            target
            url
          }
        }
      }
    }
  }
`;
